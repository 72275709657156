import React from "react"

function Card(props) {
    let panelColor = "default"
    if (props.card.idLabels.includes("57f3d43ebc40f7ae107a5147")) {
        panelColor = "danger"
    } else if (props.card.idLabels.includes("57f3d43ebc40f7ae107a5144")) {
        panelColor = "success"
    } else if (props.card.idLabels.includes("57f3d43ebc40f7ae107a5149")) {
        panelColor = "info"
    } else {
        panelColor = "warning"
    }

    const panelBodyStyles = {}
    if (props.selected) {
        panelBodyStyles.backgroundColor = "#F2AE9E"
    }

    return (
        <div className="card col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <div
                onClick={() => props.handleAssignmentSelect(props.card)}
                className={`panel panel-${panelColor}`}
            >
                <div className="panel-heading">
                    <h3 className="panel-title">
                        <a href={props.card.url} target="_blank">
                            {props.card.name}
                        </a>
                    </h3>
                </div>
                <div className="panel-body" style={panelBodyStyles}>
                    <p>{props.card.desc}</p>
                </div>
            </div>
        </div>
    )
}

export default Card
