import React, { Component } from "react"
import {
    init,
    checkForDupesAndAssign,
    getAssignedListIds,
    getCardsFromAllBoards,
    removeYourselfFromBoards
} from "./trelloApi"
import Header from "./Header"
import CardsList from "./CardsList"
import TextField from "@material-ui/core/TextField"
import RaisedButton from "@material-ui/core/Button"
import Snackbar from "@material-ui/core/Snackbar"
import Dialog from "@material-ui/core/Dialog"
import blue from "@material-ui/core/colors/blue"
import red from "@material-ui/core/colors/red"

class App extends Component {
    constructor() {
        super()
        this.state = {
            assignedDontChangeListIds: [],
            allAssignments: [],
            filteredAssignments: [],
            selectedCards: [],
            myBoards: [],
            collections: JSON.parse(localStorage.getItem("collections")) || {}, 
            chosenStartDate: localStorage.getItem("chosenStartDate") || "",
            search: "",
            showToast: false,
            toasterMessage: "",
            showModal: false,
            deleteOrgConfirm: "",
            loading: false
        }
    }

    async componentDidMount() {
        try {
            const initialState = await init()
            // If the initialState returned from init() includes the chosenOrg saved in localStorage
            // from the last visit, set that as the chosenOrg and get all the boards necessary
            initialState.chosenStartDate &&
                this.handleCollectionChange(initialState.chosenStartDate)

            // Continue to set the initialState
            this.setState(initialState)
        } catch (reason) {
            this.setState({ showToast: true, toasterMessage: reason })
        }
    }

    handleSearchChange = e => {
        const searchTerm = e.target.value.toLowerCase()
        const filteredCards = this.state.allAssignments.filter(card =>
            card.name.toLowerCase().includes(searchTerm)
        )
        this.setState({
            filteredAssignments: filteredCards,
            search: searchTerm
        })
    }

    handleCollectionChange = startDate => {
        localStorage.setItem("chosenStartDate", startDate)
        getAssignedListIds(startDate, this.state.collections[startDate])
            .then(response => 
                this.setState({
                    assignedDontChangeListIds: 
                        response.assignedDontChangeListIds,
                    showToast: true,
                    toasterMessage: response.message,
                    chosenStartDate: startDate
                })
            )
            .catch(err => 
                this.setState({
                    showToast: true,
                    toasterMessage: err
                })
            )
    }

    assignToStudents = async () => {
        this.setState({ loading: true })

        try{
            // get all cards from current boards in selected cohort ordered by boardID = ["card name", "card name"]
            const cardNamesByBoardId = await getCardsFromAllBoards(this.state.collections[this.state.chosenStartDate])

            // Loop through cards and only assign to board if it does not already have it
            await checkForDupesAndAssign(
                this.state.selectedCards, 
                cardNamesByBoardId,
                this.state.assignedDontChangeListIds
            )

            this.setState(prevState => ({
                selectedCards: [],
                search: "",
                filteredAssignments: prevState.allAssignments,
                showToast: true,
                toasterMessage: 
                    `Assigned ${
                        this.state.collections[this.state.chosenStartDate].length
                    } students up to ${
                        this.state.selectedCards.length
                    } cards each.`,
                loading: false
            }))
        }
        catch(err){
            this.setState({
                showToast: true,
                toasterMessage: err,
                loading: false
            })
        }
    }

    handleToasterClose = () =>
        this.setState({
            showToast: false,
            toasterMessage: ""
        })

    handleAssignmentSelect = assignment => {
        let newSelectedCards
        if (this.state.selectedCards.includes(assignment)) {
            newSelectedCards = this.state.selectedCards.filter(
                card => card.id !== assignment.id
            )
        } else {
            newSelectedCards = [...this.state.selectedCards, assignment]
        }
        this.setState({
            selectedCards: newSelectedCards
        })
    }

    openRemoveDialog = () => this.setState({ showModal: true })

    closeRemoveDialog = () =>
        this.setState({ showModal: false, deleteOrgConfirm: "" })

    handleDeleteOrgConfirmChange = e => {
        const value = e.target.value
        this.setState({ deleteOrgConfirm: value })
    }

    handleRemoveFromBoards = async () => {
        this.setState({ loading: true })
        try {
            await removeYourselfFromBoards(this.state.collections[this.state.chosenStartDate])
            const updatedCollections = {
                ...this.state.collections
            }
            delete updatedCollections[this.state.chosenStartDate]
            localStorage.setItem("collections", JSON.stringify(updatedCollections))
            localStorage.removeItem("chosenStartDate")
            this.setState(prevState => {
                return {
                    showToast: true,
                    toasterMessage: `Successfully removed yourself from all boards in the ${this.state.chosenStartDate} cohort`,
                    assignedDontChangeListIds: [],
                    showModal: false,
                    deleteOrgConfirm: "",
                    chosenStartDate: "",
                    collections: updatedCollections,
                    loading: false
                }
            })
        }
        catch(err){
            this.setState({
                showToast: true,
                toasterMessage: err,
                loading: false
            })
        }
        
    }

    render() {
        const isDeleteBtnDisabled =
            this.state.chosenStartDate &&
            this.state.chosenStartDate.toLowerCase() !==
                this.state.deleteOrgConfirm.toLowerCase()

        const modalActions = [
            <RaisedButton
                key="modal1"
                style={{ marginRight: 10 }}
                children={"Cancel"}
                backgroundColor={blue[500]}
                labelColor={"#ffffff"}
                onClick={this.closeRemoveDialog}
                varient="raised"
            />,
            <RaisedButton
                key="modal2"
                backgroundColor={isDeleteBtnDisabled ? "" : red[500]}
                children={"Delete Forever (no takebacks)"}
                labelColor={"#ffffff"}
                onClick={this.handleRemoveFromBoards}
                disabled={isDeleteBtnDisabled}
                varient="raised"
            />
        ]

        return (
            <div className="app-container">
                <Snackbar 
                    open={this.state.showToast}
                    message={<div className="snackbar-message">{this.state.toasterMessage}</div>}
                    onClose={this.handleToasterClose}
                    autoHideDuration={4000}
                />
                
                <Header
                    numBoardsPostingTo={
                        this.state.assignedDontChangeListIds.length
                    } 
                    handleSearchChange={this.handleSearchChange}
                    handleCollectionChange={this.handleCollectionChange}
                    chosenStartDate={this.state.chosenStartDate}
                    selectedLength={this.state.selectedCards.length}
                    collections={this.state.collections}
                    search={this.state.search}
                    assignToStudents={this.assignToStudents} 
                    openRemoveDialog={this.openRemoveDialog}
                    loading={this.state.loading}
                />
               
                <CardsList
                    cards={this.state.filteredAssignments}
                    selectedCards={this.state.selectedCards}
                    handleAssignmentSelect={this.handleAssignmentSelect}
                />
                
                {Object.keys(this.state.collections).length && (
                    <Dialog
                        title={`Permanently Deleting the ${
                            this.state.chosenStartDate
                        } Organization`}
                        open={this.state.showModal}
                        onRequestClose={this.closeRemoveDialog}
                    >
                        <p>
                            "Deleting" this organization means you'd be removing
                            all instructors from each student's board and
                            deleting the team permanently
                        </p>
                        <p>
                            The boards themselves will NOT be deleted, but
                            instead will be moved to each of the student's
                            personal boards
                        </p>
                        <p>
                            If this is what you want to do, type the full name
                            of the organization (
                            <code>
                                {this.state.chosenStartDate}
                            </code>
                            ) in the input below
                        </p>
                        <TextField
                            floatingLabelText="Organization name"
                            onChange={this.handleDeleteOrgConfirmChange}
                            value={this.state.deleteOrgConfirm}
                            name="deleteOrgConfirm"
                        />
                        {modalActions}
                    </Dialog>
                )}
            </div>
        )
    }
}

export default App
