import React from "react"
import ReactDOM from "react-dom"
import { createMuiTheme } from '@material-ui/core/styles';
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider"
import blue from "@material-ui/core/colors/blue"
import red from "@material-ui/core/colors/red"
import App from "./App"
import "./styles.css"

const theme = createMuiTheme({
    palette: {
        primary: blue,
        secondary: blue,
        error: red,
        // Used by `getContrastText()` to maximize the contrast between the background and
        // the text.
        contrastThreshold: 3,
        // Used to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    }
})

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <App />
    </MuiThemeProvider>,
    document.getElementById("root")
)

    // Initial Versions
    // "material-ui": "^0.20.0",
    // "react": "^16.5.2",
    // "react-dom": "^16.5.2",
    // "react-scripts": "2.0.5"