/** Initialize state
 * @function init 
 * @returns {Object} - Initial State
 */
export async function init() {
    try {
        const initState = {}
        await authenticate() 
        const {id: userId} = await getUserIdFromToken(localStorage.getItem("trello_token"))
        initState.myBoards = await getMyBoards(userId)  
        initState.collections = filterCollections(initState.myBoards)
        localStorage.setItem("collections", JSON.stringify(initState.collections))
        if (localStorage.getItem("chosenStartDate")) {
            initState.chosenStartDate = Object.keys(initState.collections).find(
                startDate => startDate === localStorage.getItem("chosenStartDate")
            )
        }
        const response = await getCards()
        initState.allAssignments = response.cards
        initState.filteredAssignments = response.cards
        return initState
    } catch (err) {
        throw new Error(err)
    }
}


/** Trello OAuth authenticate - generates and saves token
 * @function authenticate 
 */
function authenticate() {
    return new Promise((resolve, reject) => {
        window.Trello.authorize({
            name: "Trello Assignments",
            scope: {
                read: "true",
                write: "true"
            },
            expiration: "never",
            success: resolve,
            error: reject
        })
    })
}


/** Get's logged in User's Id 
 * @function getUserIdFromToken
 * @param {String} - token from localStorage generated on @function authenticate
 * @returns {Promise} - resolve: contains user ID || reject: fetch error
 */
function getUserIdFromToken(token){
    return new Promise((resolve, reject) => {
        window.Trello.get(
            `/tokens/${token}/member`,
            user => resolve(user),
            err => reject(err)
        )
    })
}


/** Get all assignment cards from all assignments trello list
 * @function getCards
 * @returns {Promise} - resolve: Array of assignment cards || reject: fetch error
 */
function getCards() {
    return new Promise(function(resolve, reject) {
        window.Trello.lists.get(
            "57f3d43ebc40f7ae107a513f",
            { cards: "open" },
            function(list) {
                resolve(list)
            },
            function(err) {
                console.error(err)
                reject(err)
            }
        )
    })
}


/** Gets all boards current logged user has access to
 * @function getMyBoards
 * @param {String} userId 
 * @returns {Promise} - resolve: All user boards || reject: fetch error
 */
export function getMyBoards(userId){ 
    return new Promise((resolve, reject) => {
        window.Trello.get(
            `members/${userId}/boards?lists=all`,
            boards => resolve(boards),
            err => reject(err)
        )
    })
}


/** Filter/Reduces all boards into chorts organized by start month/year
 * @function filterCollections
 * @param {Array} boards - Array of current cohorts boards
 * @returns {Object} - Object of Arrays which is `this.state.collections`
 */
function filterCollections(boards){
    return boards
        .filter(board => {
            if(board.name[0] + board.name[1] === "VS"){
                return board
            }
        })
        .reduce((final, board) => {
            const infoArr = board.name.split(" ")
            if(!final[`${infoArr[0]}_${infoArr[1]}`]) {
                final[`${infoArr[0]}_${infoArr[1]}`] = [board]
                return final
            } else {
                final[`${infoArr[0]}_${infoArr[1]}`] = [...final[`${infoArr[0]}_${infoArr[1]}`], board]
                return final
            }
        }, {})
}


/** Gets the Assigned (Don't Change) list objects for each student in current chosen cohort
 * @function getAssignedListIds
 * @param {String} startDate - Current chosen start date month/year
 * @param {Array} collection - Current cohort's boards
 * @returns {Array} - Array of objects of the "Assigned (Don't Change) Lists that have idBoard reference"
 */
export function getAssignedListIds(startDate, collection){
    return new Promise((resolve, reject) => {
        const assignedDontChangeListIds = collection.map(board => {
            const assignedDontChange = board.lists.find(
                list => list.name === "Assigned (Don't Change)"
            )
            // If this board doesn't have a list called "Assigned (Don't Change)"
            if (!assignedDontChange) {
                return reject(
                    `\nThe board ${
                        board.name
                    } doesn't have a list called 'Assigned (Don't Change)'`
                )
            }
            return assignedDontChange   
        })
        const response = {
            message: assignedDontChangeListIds.length !== 1 ?
            `Connected to ${
                assignedDontChangeListIds.length
            } boards from the ${startDate.split("_")[1] + " Cohort"}` :
            `Connected to ${
                assignedDontChangeListIds.length
            } board from the ${startDate.split("_")[1] + " Cohort"}`,
            assignedDontChangeListIds
        }
        resolve(response)
    })
}


/**
 * @function checkForDupesAndAssign
 * @param {Array} cardsToAssign - Array of cards to assign card objects
 * @param {Object} boardIDAndCards - Object of boardId's and corresponding current card's names
 * @param {Array} assignedDontChangeLists - Array of list objects with listId and boardId references
 * @returns {Promises} - An array of Promises
 */
export async function checkForDupesAndAssign(cardsToAssign, boardIDAndCards, assignedDontChangeLists){
    const promises = []

    // loop through boards
    for(let boardID in boardIDAndCards){
        // for each board loop through the cards to assign
        cardsToAssign.forEach(async card => {
            // if board does not include cardsToAssign[i].name
            if(!boardIDAndCards[boardID].includes(card.name)){
                const {id: listId} = assignedDontChangeLists.find(list => list.idBoard === boardID)
                // assign card
                await sleep(150)
                promises.push(
                    assignCard(card, boardID, listId)
                )  
            } 
        })
    }
    return Promise.all(promises)
}


/** Assigns Card to Assigned (Don't Change) list
 * @function assignCard
 * @param {Object} card - Current Card to assign
 * @param {String} boardID - Id of board that has this list
 * @param {String} listId  - Id of list being assinged to
 */
function assignCard(card, boardID, listId){
    return new Promise((resolve, reject) => {
        window.Trello.post(
            `/cards?idCardSource=${
                card.id
            }&idList=${listId}&pos=top`,
            resolve,
            err => reject(
                `There was a problem assigning ${
                    card.name
                } to board with the ID of ${boardID}`
            )
        )
    })
}


/** Gets all cards from all boards in cohort
 * @function getCardsFromAllBoards
 * @param {Array} boards - Array of board objects from current chosen cohort
 * @returns {Object} - keys: boardIDs values: [card names]
 */
export async function getCardsFromAllBoards(boards){
    const boardCards = {}
    for(let i = 0; i < boards.length; i++){
        await sleep(150)
        await window.Trello.get(
            `/boards/${boards[i].id}/cards`,
            cards => boardCards[boards[i].id] = cards,
            err => Promise.reject(err)
        )
    }
    
    const cardNamesByBoardId = {}
    for(let cards in boardCards){
        cardNamesByBoardId[cards] = []
        for(let i = 0; i < boardCards[cards].length; i++){
            cardNamesByBoardId[cards].push(boardCards[cards][i].name)
        }
    }
    return cardNamesByBoardId
}


/** Pauses async action
 * @function sleep
 * @param {String} ms - sleep time in miliseconds
 * @returns {Promise} - resolve after timeout
 */
function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


/** - Removes Logged in user from current selected cohort's boards
 * @function removeYourselfFromBoards
 * @param {Array} boards 
 * 
 */
export async function removeYourselfFromBoards(boards){
    try {
        const errors = []
        const {id: userId} = await getUserIdFromToken(localStorage.getItem("trello_token"))
        for (let board of boards) {
            await sleep(150)
            window.Trello.delete(
                `/boards/${board.id}/members/${userId}`,
                res => console.log(res),
                err => errors.push(board.name)
            )
        }
        if (errors.length) {
            throw new Error(`Couldn't remove yourself from ${errors.length} boards: ${errors.join(", ")}`)
        }
    }
    catch(err){
        console.log(err)
        return err.message
    }
}

