import React, {useState, useEffect} from "react"
import RaisedButton from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import SelectField from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Chip from "@material-ui/core/Chip"


function Header(props) {
    const { 
        collections, numBoardsPostingTo, 
        selectedLength, assignToStudents,
        chosenStartDate, search,
        handleSearchChange,
        handleCollectionChange,
        numBoardsPostedTo, loading
    } = props

    const [message, setMessage] = useState("ruining weekends...")

    const possibleMessages = [
        "ruining weekends",
        "destroying social lives",
        "generating nerd stuff",
        "ruining weekends",
        "handing out code candy",
        "giving out ALL THE THINGS",
        "ouch, paper cut..."
    ]

    useEffect(() => {
        function changeMessage(){
            setMessage(possibleMessages[Math.floor(Math.random() * possibleMessages.length)])
        }
        let intId
        if(loading){
            intId = setInterval(changeMessage, 3000)
        } else {
            clearInterval(intId)
        }
        return () => {
            clearInterval(intId)
        }
    }, [loading, possibleMessages])

    

    const headerStyles = {
        width: "100%",
        backgroundColor: "whitesmoke",
    }

    const navStyles = {
        width: "100%",
    }

    const formStyles = {
        height: 90,
        fontSize: 16,
        width: "100%",
        display: 'flex',
        justifyContent: 'space-evenly'
    }


    const buttonStyles = {
        fontSize: 16,
        width: 200,
        padding: 10,
        backgroundColor: "primary",
        height: 80,
        color: "primary"
    }

    const deleteBtnStyles = {
        fontSize: 14,
        padding: 5,
        width: 200,
        backgroundColor: "error",
        color: "error"
    }

    const boardChipStyles = {
        marginLeft: 20,
        fontSize: 16
    }

    const cardChipStyles = {
        marginLeft: 20,
        fontSize: 16
    }

    const menuItems = Object.keys(collections)
        .map((startDate, i) => (
            <MenuItem 
                key={startDate + i} 
                primarytext={startDate}
                value={startDate}>
                {startDate.split("_")[1] + " Cohort"}
            </MenuItem>
        )
    )

    const isAssignBtnDisabled =
        selectedLength === 0 || collections.length === 0
    const assignBtnText = isAssignBtnDisabled
        ? "Select Cards to Assign"
        : "Assign Cards"
    const assignBtnLabelColor =
        selectedLength && numBoardsPostingTo ? "primary" : "default"

    const isDeleteBtnDisabled = Object.keys(collections).length === 0
    const deleteBtnLabelColor = isDeleteBtnDisabled ? "" : "#ffffff"
            
    return (
        <header style={headerStyles}>
            {loading && (
                <div className="loading-modal">
                    <span className="spinner"></span>
                    <p className="loader-text">{message}</p>
                </div>  
                        
            )}
            <nav style={navStyles}>
                <form role="search" style={formStyles}>
                    <RaisedButton
                        style={buttonStyles}
                        children={assignBtnText}
                        color={assignBtnLabelColor}
                        id="post-card"
                        onClick={assignToStudents}
                        disabled={isAssignBtnDisabled}
                        varient="raised"
                    />

                    <TextField
                        type="text"
                        className={"search-box"}
                        placeholder="Search for an assignment"
                        onChange={handleSearchChange}
                        value={search}
                        name="search"
                    />

                    <SelectField
                        className="search-box"
                        placeholder="Which Start Date?"
                        value={chosenStartDate}
                        onChange={event => handleCollectionChange(event.target.value)}
                        children={menuItems}
                    />

                    <Chip
                        style={boardChipStyles}
                        color={numBoardsPostedTo > 0 ? "primary" : "default"}
                        label={`${numBoardsPostingTo} Boards`}
                    >
                    </Chip>

                    <Chip
                        style={cardChipStyles}
                        color={selectedLength ? "primary" : "default"}
                        label={`${selectedLength} ${selectedLength === 1 ? "Card" : "Cards"} Selected`}
                    >
                    </Chip>


                    {!isDeleteBtnDisabled && (
                        <RaisedButton
                            style={deleteBtnStyles}
                            className={"delete-btn"}
                            children={"Remove Yourself From Cohort Boards"}
                            backgroundcolor={deleteBtnLabelColor}
                            onClick={props.openRemoveDialog}
                        />
                    )}
                </form>
            </nav>
        </header>
    )
}

export default Header
