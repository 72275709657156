import React from "react"
import Card from "./Card.js";

function CardsList(props) {
    const allAssignments = props.cards.map(card => (
        <Card
            card={card}
            key={card.id}
            selected={props.selectedCards.includes(card)}
            handleAssignmentSelect={props.handleAssignmentSelect}
        />
    ))
    return <div>{allAssignments}</div>
}

export default CardsList
